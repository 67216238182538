<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Asociaciones Brindan Servicio
          </span>
          <small class="text-muted">SECCIÓN X</small>
        </b-media>
      </b-col>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-panel-perfil' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveForm"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow class="justify-content-center">
          <b-col
            cols="12"
            md="12"
          >
            <div class="m-2">
              <!-- Table Top -->
              <BRow>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="d-flex m-0 p-0 justify-content-end">
                    <BButton
                      v-b-modal.modal-asociacion
                      variant="primary"
                    >
                      <span class="text-nowrap">Añadir Asociacións</span>
                    </BButton>
                  </div>
                </b-col>
              </BRow>
            </div>
            <div
              v-if="items.length == 0"
              class="demo-spacing-0"
            >
              <BAlert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span><strong>SIN REGISTROS</strong> Haga click en 'Añadir
                    Asociación' para insertar una asociación</span>
                </div>
              </BAlert>
            </div>
            <BTable
              v-else
              responsive
              :items="items"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(nombre)="data">
                {{ data.item.nombre }} <br>
                {{ data.item.representante }}
              </template>
              <template #cell(datos)="data">
                {{ data.item.celular }} <br>
                {{ data.item.email }} <br>
                {{ data.item.web }}
              </template>
              <template #cell(acciones)="data">
                <span class="text-nowrap">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="editFromTable(data.item)">
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="deleteFromTable(data.item.id)">
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span></span>
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </BTable>
          </b-col>
        </BRow>
      </b-form>
    </b-card-body>
    <b-modal
      id="modal-asociacion"
      ref="insertAsociacion"
      centered
      title="Asociaciones que brindan servicios"
      no-close-on-backdrop
      size="m"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="addTable"
            >
              <feather-icon
                class="mr-50"
                icon="PlusIcon"
              />
              <span class="align-middle">Agregar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Nombre"
              label-for="nombre"
            >
              <BInputGroup class="input-group-merge form-send-message mr-1">
                <BFormInput
                  v-model="formV.nombre"
                  placeholder="Ingrese un nombre"
                />
              </BInputGroup>
            </BFormGroup>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Representante"
              label-for="representante"
            >
              <BInputGroup class="input-group-merge form-send-message mr-1">
                <BFormInput
                  v-model="formV.representante"
                  placeholder="Ingrese un representante"
                />
              </BInputGroup>
            </BFormGroup>
          </b-col>
        </BRow>
        <BRow>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Celular"
              label-for="celular"
            >
              <BInputGroup class="input-group-merge form-send-message mr-1">
                <BFormInput
                  v-model="formV.celular"
                  placeholder="Ingrese un número telefonico"
                />
              </BInputGroup>
            </BFormGroup>
          </b-col>
        </BRow>
        <BRow>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Email"
              label-for="email"
            >
              <BInputGroup class="input-group-merge form-send-message mr-1">
                <BFormInput
                  v-model="formV.email"
                  placeholder="Ingrese un correo electronico"
                />
              </BInputGroup>
            </BFormGroup>
          </b-col>
        </BRow>

        <BRow>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Web"
              label-for="web"
            >
              <BInputGroup class="input-group-merge form-send-message mr-1">
                <BFormInput
                  v-model="formV.web"
                  placeholder="Ingresa una pagina web"
                />
              </BInputGroup>
            </BFormGroup>
          </b-col>
        </BRow>
      </b-form>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BButtonGroup,
  BRow,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdownItem,
    BDropdown,
    BButton,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BButtonGroup,
    BRow,
    BFormGroup,
    BTable,
    BAlert,
    BInputGroup,
    BFormInput,
  },
  props: {
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        'Nombre',
        { key: 'datos', label: 'Datos de Contacto' },
        'Acciones',
      ],
      items: [
        {
          id: 1,
          nombre: 'Asociación de la Mujer',
          representante: 'Luisa',
          celular: '123456789',
          email: 'correo',
          web: 'sin pagina web',
        },
      ],
    }
  },
  computed: {
    isOtroBasico() {
      return this.selectedCheckBoxesBasica.includes(6)
    },
    isOtroTuristico() {
      return this.selectedCheckBoxesTuristica.includes(10)
    },
  },
  methods: {
    closeModal() {
      this.clearFormV()
      this.$refs.insertAsociacion.hide()
    },
    clean() {
      this.items = []
    },
    addTable() {
      this.$refs.insertAsociacion.hide()
      const id = this.items.length + 1
      this.items.push({
        id,
        ...this.formV,
      })
      this.clearFormV()
    },
    editFromTable(item) {
      this.$refs.insertAsociacion.show()
      this.formV = {
        nombre: item.nombre,
        representante: item.representante,
        celular: item.celular,
        email: item.email,
        web: item.web,
      }
    },

    deleteFromTable(event) {
      const index = this.items.findIndex(item => item.id === event)
      this.items.splice(index, 1)
    },
    clearFormV() {
      this.formV = {
        nombre: '',
        representante: '',
        celular: '',
        email: '',
        web: '',
      }
    },
  },
  setup(props, { emit }) {
    const formV = ref({
      especiF: '',
      horario: '',
      tiempo: '',
      recomendaciones: '',
    })
    const isBusy = ref(false)
    const saveForm = async () => {
      // Validar datos antes
      /* const service = 'plataforma/ATRACTIVO_CREATE' */
      /* await store.dispatch(service, dataRegister.value)
          .then(response => {
            console.log('oh yeah', response)
          })
          .catch(error => {
            console.log('errror : ', error)
          }) */ emit(
        'error-data',
        'error',
      )
    }

    return {
      saveForm,
      isBusy,
      formV,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
