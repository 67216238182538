<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Datos Generales
          </span>
          <small class="text-muted">SECCIÓN I</small>
        </b-media>
      </b-col>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-panel-perfil' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveForm"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <h6 class="text-dark">
          <feather-icon icon="ClipboardIcon" />
          <span class="align-middle ml-25">Ficha Básica</span>
        </h6>

        <hr
          style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
        >
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Destino Turístico"
              label-for="destino"
            >
              <v-select
                id="destino"
                v-model="destinoSel"
                label="nombre"
                :reduce="m => m.nombre"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="destinos"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="9"
          >
            <b-form-group
              label="Nombre Atractivo"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                v-model="dataRegister.nombre"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Descripción"
              label-for="descripcion"
            >
              <quill-editor
                id="descripcion"
                v-model="dataRegister.descripcion"
                :options="editorOption"
                class="border-bottom-0"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Particularidad"
              label-for="particularidad"
            >
              <quill-editor
                id="particularidad"
                v-model="dataRegister.particularidad"
                :options="editorOption"
                class="border-bottom-0"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <h6 class="text-dark">
          <feather-icon icon="MapPinIcon" />
          <span class="align-middle ml-25">Datos de Ubicación</span>
        </h6>

        <hr
          style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
        >

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Departamento"
              label-for="dpto"
            >
              <v-select
                v-model="dptoSel"
                :reduce="m => m.idUbigeo"
                label="descripcion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dptos"
                @input="getUbigeos('prov', dptoSel.slice(0, 2))"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Provincia"
              label-for="prov"
            >
              <v-select
                v-model="provSel"
                :reduce="m => m.idUbigeo"
                label="descripcion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="provs"
                @input="getUbigeos('dist', provSel.slice(0, 4))"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Distrito"
              label-for="dist"
            >
              <v-select
                v-model="distSel"
                :reduce="m => m.idUbigeo"
                label="descripcion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dists"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Dirección"
              label-for="direccion"
            >
              <b-form-input
                id="direccion"
                v-model="dataRegister.direccion"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Referencia"
              label-for="referencia"
            >
              <b-form-input
                id="referencia"
                v-model="dataRegister.referencia"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Latitud"
              label-for="latitud"
            >
              <b-form-input
                id="latitud"
                v-model="dataRegister.latitud"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Longitud"
              label-for="longitud"
            >
              <b-form-input
                id="longitud"
                v-model="dataRegister.longitud"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <h6 class="text-dark">
          <feather-icon icon="BookmarkIcon" />
          <span class="align-middle ml-25">Categorización Mincetur</span>
        </h6>

        <hr
          style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
        >

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Categoría"
              label-for="categoria"
            >
              <v-select
                v-model="categoriaRecursoSel"
                :reduce="m => m.idCategoriaRecurso"
                label="descripcion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoriasRecurso"
                @input="getTiposRecurso()"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Tipo de Recurso"
              label-for="tipoRecurso"
            >
              <v-select
                v-model="tipoRecursoSel"
                :reduce="m => m.idTipoRecurso"
                label="descripcion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tiposRecurso"
                @input="getSubTiposRecurso()"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Sub Tipo de Recurso"
              label-for="subTipoRecurso"
            >
              <v-select
                v-model="subTipoRecursoSel"
                :reduce="m => m.idSubTipoRecurso"
                label="descripcion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subTiposRecurso"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BRow,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BRow,
    vSelect,
    BButtonGroup,
    quillEditor,
  },
  props: {
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {}
  },
  setup(props, { emit }) {
    const isBusy = ref(false)
    const destinoSel = ref({})
    const destinos = ref([])
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const dataRegister = ref(props.dataEdit)
    const categoriasRecurso = ref([])
    const categoriaRecursoSel = ref({})
    const tiposRecurso = ref([])
    const tipoRecursoSel = ref({})
    const subTiposRecurso = ref([])
    const subTipoRecursoSel = ref({})
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      // ['clean'],
    ]

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getCategoriasRecurso = async () => {
      await store
        .dispatch('catalogo/CATEGORIA_RECURSO_FIND_ALL', {
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            categoriasRecurso.value = response.items
            tipoRecursoSel.value = {}
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getTiposRecurso = async () => {
      await store
        .dispatch('catalogo/TIPO_RECURSO_FIND_ALL', {
          idCategoriaRecurso: categoriaRecursoSel.value,
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            tiposRecurso.value = response.items
            tipoRecursoSel.value = {}
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getSubTiposRecurso = async () => {
      await store
        .dispatch('catalogo/SUBTIPO_RECURSO_FIND_ALL', {
          idCategoriaRecurso: categoriaRecursoSel.value,
          idTipoRecurso: tipoRecursoSel.value,
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            subTiposRecurso.value = response.items
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      // Validar datos antes
      /*  const service = 'plataforma/ATRACTIVO_CREATE' */
      dataRegister.value.destino = destinoSel.value
      dataRegister.value.idCategoriaRecurso = categoriaRecursoSel.value
      dataRegister.value.idTipoRecurso = tipoRecursoSel.value
      dataRegister.value.idSubTipoRecurso = subTipoRecursoSel.value
      /* console.log(service, dataRegister.value) */
      /* await store.dispatch(service, dataRegister.value)
        .then(response => {
          console.log('oh yeah', response)
        })
        .catch(error => {
          console.log('errror : ', error)
        }) */
    }

    const loadData = async () => {
      await getDestinos()
      dptoSel.value = `${dataRegister.value.ubigeo.idUbigeo.slice(0, 2)}0000`
      await getUbigeos('dpto', '')
      await getUbigeos('prov', dataRegister.value.ubigeo.idUbigeo.slice(0, 2))
      provSel.value = `${dataRegister.value.ubigeo.idUbigeo.slice(0, 4)}00`
      await getUbigeos('dist', dataRegister.value.ubigeo.idUbigeo.slice(0, 4))
      distSel.value = dataRegister.value.ubigeo.idUbigeo
      await getCategoriasRecurso()
      destinoSel.value = dataRegister.value.destino
      await getCategoriasRecurso()
      categoriaRecursoSel.value = dataRegister.value.idCategoriaRecurso
      await getTiposRecurso()
      tipoRecursoSel.value = dataRegister.value.idTipoRecurso
      await getSubTiposRecurso()
      subTipoRecursoSel.value = dataRegister.value.idSubtipoRecurso
    }

    loadData()

    return {
      isBusy,
      dataRegister,
      getDestinos,
      destinos,
      destinoSel,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      getTiposRecurso,
      getSubTiposRecurso,
      saveForm,
      editorOption,
      categoriasRecurso,
      categoriaRecursoSel,
      tiposRecurso,
      tipoRecursoSel,
      subTiposRecurso,
      subTipoRecursoSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
